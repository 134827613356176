import { c, Notification, NotificationAction } from './types'

export const INIT_NOTIFICATION: Notification = {
  title: "",
  message: "",
  severity: undefined,
  autohide: undefined
}

export const notification = (state: Notification = INIT_NOTIFICATION, action: NotificationAction): Notification => {
  switch (action.type) {
      case c.SET_NOTIFICATION:
          return action.payload
      default:
          return state
  }
}