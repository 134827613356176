import React from 'react'
import { PulseLoader } from "react-spinners"

// Types
import { PropsFromRedux } from "./index"

// MUI
import { Box, Typography,  } from '@material-ui/core'
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      border: `1px dashed  ${theme.palette.secondary.main}`,
      borderRadius: "10px",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "center"
    }
  })
)


export const ValidationUi = (props: PropsFromRedux) => {
  const cls = useStyles()
  const theme = useTheme()

  return (

    <Box mb={2} className={cls.container}>
      <PulseLoader
        size={20}
        margin={10}
        color={theme.palette.primary.main}
        loading={true}
      />
      <Box my={1} />
      <Typography variant="body1">Completed: {props.currentStage}/{props.totalStage}</Typography>
    </Box>
  )
}