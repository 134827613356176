import React from 'react'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import Notification from '../notification'
import Steps from '../Steps'
import Dropzone from '../dropzone'
import Validation from '../validation'
import Confirmation from '../confirmation'
import Comment from '../comment'
import Progress from '../progress'
import Done from '../Done'

//Values
import { INIT_OPTIONS } from '../../store/options/reducer'

// Types
import { PropsFromRedux } from "./index"
import { Options } from '../../store/options/types'

// MUI
import { Box } from '@material-ui/core'

import './app.css'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const AppUi = (props: PropsFromRedux) => {
  const query = useQuery()
  const [step, setStep] = React.useState(0)

  // Get URL options on component mount
  React.useEffect(() => {
    const options = generateOptions(query)
    props.setOptions(options)
    // eslint-disable-next-line
  }, [])
  React.useEffect(() => {
    if (props.steps.import) {
      setStep(0)
    }
    if (props.steps.validation || props.steps.confirmation)  {
      setStep(1)
    }
    if (props.steps.comment) {
      setStep(2)
    }
    if (props.steps.upload) {
      setStep(3)
    }
    if (props.steps.done) {
      setStep(4)
    }
  }, [props.steps])


  return (
    <>
    <Notification />
    <Box mx={2} my={2}>
      <Steps activeStep={step} />
    </Box>
    <Box mx={2} my={1.5}>
      { props.steps.import ? (
        <Dropzone />
      ) : null}
      { props.steps.validation ? (
        <Validation />
      ) : null}
      { props.steps.confirmation ? (
        <Confirmation />
      ) : null}
      { props.steps.comment ? (
        <Comment />
      ) : null}
      { props.steps.upload ? (
        <Progress/>
      ) : null}
      { props.steps.done ? (
        <Done />
      ) : null}
    </Box>
    </>
  )
}

const generateOptions = (search: URLSearchParams): Options => {
  const sessionDate = search.get("sessionDate") ? search.get("sessionDate") as string : "-"
  const date = moment(sessionDate)
  let mode = ""
  switch (search.get('mode')) {
    case 'testShoot':
      mode = 'testShoot'
      break
    default:
      mode = 'default'
      break
  }
  return {
    sessionInfo: {
      id: search.get("id") ? search.get("id") as string : "",
      firstName: search.get("firstName") ? search.get("firstName") as string : "-",
      lastName: search.get("lastName") ? search.get("lastName") as string : "-",
      photographerName: search.get("photographerName") ? search.get("photographerName") as string : "-",
      templateName: search.get("templateName") ? search.get("templateName") as string : "-",
      cityName: search.get("cityName") ? search.get("cityName") as string : "-",
      sessionDate: date,
    },
    mode: mode,
    failOnCount: search.get("failOnCount") ? true : false,
    maxImg: search.get("maxImg") ? parseInt(search.get("maxImg") as string) : INIT_OPTIONS.maxImg,
    minImg: search.get("minImg") ? parseInt(search.get("minImg") as string) : INIT_OPTIONS.minImg,
    failOnSize: search.get("failOnSize") ? true : false,
    minSize: search.get("minSize") ? parseInt(search.get("minSize") as string) : INIT_OPTIONS.minSize,
    minAvgSize: search.get("minAvgSize") ? parseInt(search.get("minAvgSize") as string) : INIT_OPTIONS.minAvgSize,
    comment: INIT_OPTIONS.comment
  }
}