import React from 'react'

import { Box, Typography } from '@material-ui/core'
import { Theme, makeStyles, createStyles } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
			width: 150,
			height: 150,
			color: theme.palette.success.main
    }
  })
)

export default () => {
  const cls = useStyles()

  return (
    <Box display="flex" flexDirection="column" flexGrow={1} alignItems="center" justifyContent="center">
			<CheckCircle className={cls.icon}/>
			<Box my={1}/>
			<Typography variant="body1">All Done!</Typography>
			<Box my={0.5}/>
			<Typography variant="caption"><b>Note:</b> It can take up to a minute for the pictures to be registered in our system.</Typography>
    </Box>
  )
}