import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/app'

import { ThemeProvider } from '@material-ui/core/styles'
import shoottTheme from './MuiShoott'

import { BrowserRouter as Router } from "react-router-dom"

import { store } from './store/index'
import { Provider } from 'react-redux'


declare global {
  interface Window { store: any }
}
window.store = store

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ThemeProvider theme={shoottTheme}>
        <App />
      </ThemeProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
)
