import { connect, ConnectedProps } from "react-redux"

import CommentUi from "./Comment"

// Actions
import { setComment } from '../../store/options/actions'
import { uploadFiles } from '../../store/images/actions'

// Types
import { ThunkDispatch } from "redux-thunk"
import { AppState, AppActions } from "../../store/types"


const mapState = (state: AppState) => {
  return {
    comment: state.options.comment,
    clientFirstName: state.options.sessionInfo?.firstName,
    mode: state.options.mode
  }
}

const mapDispatch = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return {
    skip() {
      dispatch(setComment({
        state: "reject",
        text: ''
      }))
      dispatch(uploadFiles())
    },
    addComment(text: string) {
      dispatch(setComment({
        state: "use",
        text: text
      }))
      dispatch(uploadFiles())
    }
  }
}

const connector = connect(mapState, mapDispatch)
export type PropsFromRedux = ConnectedProps<typeof connector>


export default connector(CommentUi)