import { connect, ConnectedProps } from "react-redux"

import { ProgressUi } from "./Progress"

// Actions

// Types
import { ThunkDispatch } from "redux-thunk"
import { AppState, AppActions } from "../../store/types"


const mapState = (state: AppState) => {
  return {
    files: state.images.files,
  }
}

const mapDispatch = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return {}
}

const connector = connect(mapState, mapDispatch)
export type PropsFromRedux = ConnectedProps<typeof connector>


export default connector( ProgressUi )