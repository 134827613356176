import { c, Comment, Options, OptionsAction } from './types'

export const INIT_OPTIONS: Options = {
  mode: 'default',
  minImg: 40,
  maxImg: 1000,
  failOnCount: false,
  minSize: 2,
  minAvgSize: 4,
  failOnSize: false,
  comment: {
    state: "hide",
    text: ""
  }
}


export const options = (state: Options = INIT_OPTIONS, action: OptionsAction): Options => {
  switch (action.type) {
    case c.SET_OPTIONS:
      return {
        ...action.payload as Options
      }
    case c.SET_COMMENT_STATE:
      return {
        ...state,
        comment: {
          ...state.comment,
          state: action.payload as Comment['state']
        }
      }
    case c.SET_COMMENT:
      return {
        ...state,
        comment: action.payload as Comment
      }
    default:
      return state
  }
}