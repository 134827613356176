import { combineReducers, createStore, applyMiddleware } from 'redux'

// Reducers
import { notification } from './notification/reducer'
import { options } from './options/reducer'
import { images } from './images/reducer'


// Middleware
import thunk, { ThunkMiddleware } from 'redux-thunk'
import { createLogger } from 'redux-logger'

// Types
import { AppState, AppActions } from './types'

const rootReducer = combineReducers({
    notification,
    options,
    images,
})

const logger = createLogger({
    duration: true,
    collapsed: true
})

export const store = createStore (
    rootReducer,
	applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>, logger)
)