import moment from 'moment'
import { validateImages } from './validate'
import { uploadAllFiles, uploadDoneFile } from './upload'

// Other actions
import { setNotification, clearNotification } from '../notification/actions'
import { setCommentState } from '../options/actions'

// Types
import { Dispatch } from "redux"
import { AppState, AppActions } from "../types"
import { c, Images, ImagesActions } from "./types"
import { Options } from '../options/types'


export const addFiles = (files: File[]) => async ( dispatch: Dispatch<AppActions>, getState: () => AppState ) => {
  const currentFiles = getState().images.files.map( f => f.image )

  const newFiles: Images['files'] = []

  for (const f of files) {
    if (currentFiles.find( cf => cf.name === f.name)) {
      dispatch(setNotification({
        severity: "warning",
        title: "Duplicate Found",
        message: `${f.name} has already been imported and was ignored`
      }))
    }
    else {
      newFiles.push({
        name: f.name,
        image: f,
        progress: 0,
        transfered: false
      })
    }
  }

  dispatch({
    type: c.ADD_FILES,
    payload: newFiles
  })
}
export const removeFile = (index: number): ImagesActions => {
  return {
    type: c.REMOVE_FILE,
    payload: index
  }
}
export const clearFiles = (): ImagesActions => {
  return {
    type: c.CLEAR_FILES,
    payload: undefined
  }
}
export const setFileProgress = (fileName: string, progress: number, transfered?: boolean) => async ( dispatch: Dispatch<AppActions>, getState: () => AppState ) => {
  const files = getState().images.files
  const index = files.findIndex( f => f.name === fileName)

    dispatch({
      type: c.SET_FILE_PROGRESS,
      payload: {
        index: index,
        progress: progress,
        transfered: transfered ? transfered : false
      } 
    })
}
export const setAllDone = (): ImagesActions => {
  return {
    type: c.SET_ALL_DONE,
    payload: true
  }
}

const setIsValidating = (valid: boolean): ImagesActions => {
  return {
    type: c.SET_FILES_VALIDATING,
    payload: valid
  }
}
export const setIsValid = (valid: boolean): ImagesActions => {
  return {
    type: c.SET_FILES_VALID,
    payload: valid
  }
}
export const setValidationStage = (stage: number): ImagesActions => {
  return {
    type: c.SET_VALIDATION_STAGE,
    payload: stage
  }
}
export const clearValidationStage = (): ImagesActions => {
  return {
    type: c.CLEAR_VALIDATION_STAGE,
    payload: undefined
  }
}
const setIsUploading = (uploading: boolean): ImagesActions => {
  return {
    type: c.SET_FILES_UPLOADING,
    payload: uploading
  }
}

export const addConfirmationMessage = (message: string): ImagesActions => {
  return {
    type: c.ADD_CONFIRMATION_MESSAGE,
    payload: message
  }
}
export const clearConfirmationMessages = (): ImagesActions => {
  return {
    type: c.CLEAR_CONFIRMATION_MESSAGE,
    payload: undefined
  }
}


export const validateFiles = () => async ( dispatch: Dispatch<AppActions>, getState: () => AppState ) => {
  dispatch(setIsValidating(true))
  const images = getState().images.files.map( f => f.image )
  const clientFirstName = getState().options.sessionInfo?.firstName
  const mode = getState().options.mode

  try {
    if (mode === 'default') {
      dispatch(addConfirmationMessage(`I have double checked to make sure the photo on the left is indeed my client, ${clientFirstName}.`))
    }
    await validateImages(images, getState().options)
    dispatch(setIsValidating(false))
    dispatch(setIsValid(true))
    if (getState().images.confirmation.length === 0) {
      dispatch(setCommentState('show'))
    }
  } catch (error) {
    // Clearing confirmation since breaking error was found
    dispatch(clearConfirmationMessages())
    dispatch(setIsValidating(false))
    dispatch(
      setNotification({
        severity: "error",
        title: "Error During Validation",
        message: error.message,
        autohide: null
      })
    )
  }

}
export const uploadFiles = () => async ( dispatch: Dispatch<AppActions>, getState: () => AppState ) => {
  const images = getState().images.files.map( f => f.image )
  const options = getState().options
  const folderNames = buildFolderName(getState().options)

  dispatch(setIsUploading(true))
  dispatch(clearNotification())

  try {
    await uploadAllFiles(images, folderNames)
    await uploadDoneFile({
      mode: options.mode,
      comment: options.comment.text,
      confirmationMessages: getState().images.confirmation
    }, folderNames)
    dispatch(setAllDone())
  } catch (error) {
    dispatch(setIsValid(false))
    dispatch(setCommentState("hide"))
    dispatch(setNotification({
      severity: "error",
      title: "Upload Error",
      message: error.message,
      autohide: null
    }))
  }

  dispatch(clearConfirmationMessages())
  dispatch(setIsUploading(false))
}

const buildFolderName = (options: Options): string[] => {
  const now = moment().unix()
  if (options.mode === "testShoot") {
    const info = options.sessionInfo
    if (info) {
      return ['testShoot', `${info.photographerName}_${info.cityName}_${now}_${info.id}`]
    }
    return ['testShoot', 'unknown']
  }

  // Default Mode
  const info = options.sessionInfo
  if (info) {
    const eventDate = info.sessionDate.isValid() ? info.sessionDate.format("YYYY-MM-DD h:mma") : "-"
    return [`${info.firstName} ${info.lastName}_${info.photographerName}_${info.templateName}_${info.cityName}_${eventDate}_${now}_${info.id}`]
  }
  return ['unknown']
}
