import React from "react"

// Types
import { PropsFromRedux } from "./index"

// MUI & CSS
import { Theme, makeStyles, createStyles } from '@material-ui/core'
import { Box, Grid, Container, Card, CardContent } from "@material-ui/core"
import { TextField, Button, Typography } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginTop: theme.spacing(2),
      '&:hover': {
        backgroundColor: theme.palette.grey['300'],
        cursor: 'pointer'
      }
    }
  })
)

const CommentUi = (props: PropsFromRedux) => {
  const cls = useStyles()
  const [text, setText] = React.useState('')

  React.useEffect(() => {
    setText(props.comment.text)
  }, [props.comment.text])

  React.useEffect(() => {
    if (props.comment.state === 'show' && props.mode === 'testShoot') {
      props.skip()
    }
  })
  const SUGGESTIONS = [
    `Hi ${props.clientFirstName},\nI loved meeting and photographing you! I had so much fun and I’m so excited to share your photos with you – you look amazing!`,
    `Hi ${props.clientFirstName},\nI loved meeting and photographing you and your lovely family! We captured some amazing photos together and I’m so excited to share them with you!`,
    `Hi ${props.clientFirstName},\nIt was such an honor and pleasure to photograph you guys – you have so many beautiful photos together and you all look truly amazing!`
  ]
                
  return (
    <>
    <Container >
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography variant="h5">
            Suggestions
          </Typography>
          <Card className={cls.card} onClick={()=>setText(SUGGESTIONS[0])}>
            <CardContent>
              <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>
                {SUGGESTIONS[0]}
              </Typography>
            </CardContent>
          </Card>
          <Card className={cls.card} onClick={()=>setText(SUGGESTIONS[1])}>
            <CardContent>
              <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>
                {SUGGESTIONS[1]}
              </Typography>
            </CardContent>
          </Card>
          <Card className={cls.card} onClick={()=>setText(SUGGESTIONS[2])}>
            <CardContent>
              <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>
                {SUGGESTIONS[2]}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
            <TextField
              value={text}
              onChange={(e) => setText(e.target.value)}
              style={{width: "100%"}}
              variant="outlined"
              label="Your Message"
              multiline
              rows={8}
            />
            <Box my={1}/>
            <Typography variant="caption" style={{whiteSpace: 'pre-line'}}>
              {`NOTE: Your client will see this message when viewing their gallery. Feel free to use the suggestions provided.`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
      <Button
        onClick={()=>props.skip()}
        variant="outlined"
        color="secondary"
        style={{width:"160px"}}
      >
        Skip
      </Button>
      <Box mx={2} />
      <Button
        onClick={()=>props.addComment(text)}
        variant="contained"
        color="primary"
        style={{width:"160px"}}
      >        
        Add Comment
      </Button>
    </Box>
    </>
  )

}

export default CommentUi