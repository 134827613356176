export const c = {
    SET_NOTIFICATION: "SET_NOTIFICATION"
}

export interface Notification {
    severity: "success" | "info" | "warning" | "error" | undefined
    title: String
    message: string
    autohide?: number | null
}

export interface NotificationAction {
    type: string
    payload: Notification
}