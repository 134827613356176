import { connect, ConnectedProps } from "react-redux"

import { NotificationUi } from "./Notification"

// Actions
import { clearNotification } from '../../store/notification/actions'

// Types
import { ThunkDispatch } from "redux-thunk"
import { AppState, AppActions } from "../../store/types"

const mapState = (state: AppState) => {
  return {
    open: state.notification.severity !== undefined,
    title: state.notification.title,
    message: state.notification.message,
    severity: state.notification.severity,
    autoHide: state.notification.autohide,
  }
}

const mapDispatch = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return {
    closeNotification() {
      dispatch(clearNotification())
    }
  }
}

const connector = connect(mapState, mapDispatch)
export type PropsFromRedux = ConnectedProps<typeof connector>


export default connector(NotificationUi)