/********************************** SHOOTT MATERIAL UI THEME **********************************/
// #c4974c -> darker gold
// #e7ce99 -> lighter gold
// rgb(228, 209, 153) light
// rgb(205, 182, 113) dark
// linear-gradient(rgb(228, 209, 153) 0%, rgb(205, 182, 113) 100%)
/**********************************************************************************************/

import { createMuiTheme } from '@material-ui/core/styles'

const lightGold = "#e7ce99"
const darkGold = "#c4974c"
const textPrimary = 'rgba(0, 0, 0, 0.87)'
const textSecondary = 'rgba(0, 0, 0, 0.54)'
const titleFonts = [
    'PT Serif',
    'sans-serif',
    'Arial'
].join(',')
const baseFonts = [
    'Quicksand',
    'PT Serif',
    'sans-serif',
    'Arial'
].join(',')


export default createMuiTheme({
    palette: {
      common:{
        lightGold: `${lightGold}`,
        darkGold: `${darkGold}`,
      },
      primary: {
        main: `${lightGold}`,
      },
      secondary: {
        main: `${darkGold}`,
      },
      text: {
        primary: textPrimary,
        secondary: textSecondary
      }
    },
    typography: {
      fontSize: 14,
      fontFamily: baseFonts,
      h1: {
        fontFamily: titleFonts
      },
      h2: {
        fontFamily: titleFonts
      },
      h3: {
        fontFamily: titleFonts
      },
      h4: {
        fontFamily: titleFonts
      },
      h5: {
        fontFamily: titleFonts
      },
      h6: {
        fontFamily: titleFonts
      }
    },
    overrides: {
      // Override Default Checkbox color
      MuiCheckbox:{
        root: {
          color: darkGold,
        }
      },
      // Override disabled state of TextFields
      MuiInputLabel: {
        root: {
          color: darkGold,
          '&$disabled': {
            color: textSecondary,
          },
          '&$focused': {
            color: darkGold,
          }
        }
      },
      MuiInput: {
        underline: {
          '&:after': {
            borderBottomWidth: '2px',
            borderBottomColor: darkGold,
            borderBottomStyle: 'solid'
          },
          '&$disabled:before': {
            borderBottomWidth: '1px',
            borderBottomColor: textSecondary,
            borderBottomStyle: 'solid'
          },
        }
      },
      MuiInputBase: {
        root: {
          '&$disabled': {
            color: textPrimary,
            cursor: 'default',
          },
        }
      }
    }
})