import { connect, ConnectedProps } from "react-redux"

import { DropzoneUi } from "./Dropzone"

// Actions
import { addFiles, clearFiles, validateFiles, removeFile } from "../../store/images/actions"
import { setComment } from "../../store/options/actions"

// Types
import { ThunkDispatch } from "redux-thunk"
import { AppState, AppActions } from "../../store/types"
import { Comment } from '../../store/options/types'

const mapState = (state: AppState) => {
  return {
    disabled: state.options.sessionInfo?.id === "",
    hasFiles: state.images.files.length !== 0,
    files: state.images.files,
    validationStepText: state.images.validationStage,
    mode: state.options.mode,
  }
}

const mapDispatch = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return {
    addFiles(files: File[]) {
      dispatch(addFiles(files))
    },
    removeFile(index: number) {
      dispatch(removeFile(index))
    },
    clearFiles() {
      dispatch(clearFiles())
    },
    validateFiles() {
      dispatch(validateFiles())
    },
    setComment(comment: Comment) {
      dispatch(setComment(comment))
    }
  }
}

const connector = connect(mapState, mapDispatch)
export type PropsFromRedux = ConnectedProps<typeof connector>


export default connector(DropzoneUi)