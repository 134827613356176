export const c = {
  ADD_FILES: "SET_FILES",
  REMOVE_FILE: "REMOVE_FILE",
  CLEAR_FILES: "CLEAR_FILES",
  SET_FILE_PROGRESS: "SET_FILE_PROGRESS",
  SET_ALL_DONE: "SET_ALL_DONE",

  SET_FILES_VALIDATING: "SET_FILES_VALIDATING",
  SET_FILES_VALID: "SET_FILES_VALID",
  SET_VALIDATION_STAGE: "SET_VALIDATION_STAGE",
  CLEAR_VALIDATION_STAGE: "CLEAR_VALIDATION_STAGE",

  SET_FILES_UPLOADING: "SET_FILES_UPLOADING",

  ADD_CONFIRMATION_MESSAGE: "ADD_CONFIRMATION_MESSAGE",
  CLEAR_CONFIRMATION_MESSAGE: "CLEAR_CONFIRMATION_MESSAGE"
}


export interface Images {
  files: {
    name: string
    image: File
    progress: number
    transfered: boolean
  }[]

  areValidating: boolean
  areValid: boolean
  validationStage: number //incrementing number
  areUploading: boolean
  allDone: boolean

  confirmation: string[]
}

interface FilesAction {
  type: string
  payload: Images['files']
}
interface FilesProgressAction {
  type: string
  payload: {
    index: number
    progress: number
    transfered: boolean
  }
}
interface BooleanAction {
  type: string
  payload: boolean
}
interface NumberAction {
  type: string
  payload: number
}
interface StringAction {
  type: string
  payload: string
}
interface ClearAction {
  type: string
  payload: undefined
}
export type ImagesActions = 
  | FilesAction
  | FilesProgressAction
  | BooleanAction
  | NumberAction
  | StringAction
  | ClearAction