import { connect, ConnectedProps } from "react-redux"

import { ValidationUi } from "./Validation"

// Actions

// Types
import { ThunkDispatch } from "redux-thunk"
import { AppState, AppActions } from "../../store/types"

const mapState = (state: AppState) => {
  return {
    totalStage: state.images.files.length,
    currentStage: state.images.validationStage,
  }
}

const mapDispatch = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return {}
}

const connector = connect(mapState, mapDispatch)
export type PropsFromRedux = ConnectedProps<typeof connector>


export default connector(ValidationUi)