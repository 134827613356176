import React from 'react'

// Types
import { PropsFromRedux } from "."

// Material UI
import { Alert, AlertTitle } from '@material-ui/lab'
import { Snackbar, Box } from '@material-ui/core'

export const NotificationUi = (props: PropsFromRedux) => {

  return (
    <Snackbar
      open={props.open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={props.autoHide}
      onClose={props.closeNotification}
    >
      <Box display="flex" style={{flexDirection: "column"}}>
        <Alert onClose={props.closeNotification} severity={props.severity}>
          <AlertTitle>{props.title}</AlertTitle>
          {props.message}
        </Alert>
      </Box>
    </Snackbar>
  )
}