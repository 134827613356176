import React from 'react'

// Types
import { PropsFromRedux } from "./index"

// MUI
import { Box, Grid, LinearProgress, Typography } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core'
import { CloudUpload, Done } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    done: {
      color: theme.palette.success.main
    },
    container: {
      height: "400px",
      overflowY: "auto",
      overflowX: "hidden",
    },
  })
)

export const ProgressUi = (props: PropsFromRedux) => {
  const cls = useStyles()

  return (
    <Box className={cls.container} mb={2}>
    {props.files.map( (f, i) => (
      <Grid key={i} container spacing={2}>
        <Grid item xs={1}>
          <Box display="flex" justifyContent="flex-end">
            <CloudUpload fontSize="large"/>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Box display="flex"justifyContent="space-between">
            <Typography display="inline" variant="caption">{f.name}</Typography>
            <Typography display="inline" variant="caption">
              {(((f.progress/100)*f.image.size)/1000000).toFixed(1)} of {(f.image.size/1000000).toFixed(1)}MB
            </Typography>
          </Box>

          <LinearProgress variant="determinate" value={f.progress} style={{width:"100%"}}/>

        </Grid>
        <Grid item xs={1}>
          <Box display="flex" alignItems="center" style={{height:"100%"}}>
            {f.transfered ? (<Done className={cls.done} />) : null}
          </Box>
        </Grid>
      </Grid>
    ))}
    </Box>
  )
}