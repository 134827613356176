import React from 'react'

// Types
import { PropsFromRedux } from "./index"

// MUI
import { Typography, Box, Button, Checkbox, Grid } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "400px",
      overflowY: "auto",
      overflowX: "hidden",
    },
  })
)

export const ConfirmationUi = (props: PropsFromRedux) => {
  const cls = useStyles()
  const [img, setImg] = React.useState('')
  const [confirmed, setConfirmed] = React.useState(false)

  React.useEffect(()=> {
    toDataUri(props.firstFile).then( d => {
      setImg(d)
    })
  }, [props.firstFile])

  return (
    <>
      <Box display="flex" mb={2}>
        <Grid container>
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              {img === "" ? "Loading...": <img alt="First" src={img} style={{maxHeight: "380px"}}/>}
              <Typography variant="caption">{props.firstFile.name}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={cls.container}>
              {props.isDefault ? (<>
                <Typography variant="h5" color="secondary">Confirm Client</Typography>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    checked={confirmed}
                    onChange={()=>setConfirmed(!confirmed)}
                  />
                  <Typography style={{fontWeight:"bold", height: "fitContent"}}>
                    {props.conditions[0]}
                  </Typography>
                </Box>
              </>) : null}

              <Typography variant="h5" color="secondary">Warnings</Typography>
              <Typography variant="body1">By clicking 'Accept' you sign off the following warnings:</Typography>
              {props.conditions.slice(props.isDefault ? 1 : 0).map((c, i) => (
                <Typography key={i}>
                  {`- ${c}`}
                </Typography>
              ))}
            </Box>
          </Grid>
        </Grid>

        
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          variant="outlined"
          color="secondary"
          onClick={props.decline}
          style={{width:"160px"}}
        >
          Back
        </Button>
        <Box mx={2} />
        <Button
          variant="contained"
          color="primary"
          onClick={props.accept}
          style={{width:"160px"}}
          disabled={!confirmed && props.isDefault}
        >        
          Accept {props.numberOfFiles} Files
        </Button>
      </Box>
    </>
  )
}

const toDataUri = (file: File, removeUri: boolean = false): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const uri = reader.result as string
      if (removeUri) {
        resolve(uri.split(',')[1])
      } else {
        resolve(uri)
      }
    }
    reader.onerror = error => reject(error)
  })
}