// Other actions
import { setNotification } from '../notification/actions'

// Types
import { Dispatch } from "redux"
import { AppState, AppActions } from "../types"
import { c, Comment, Options, OptionsAction } from "./types"


export const setOptions = (options: Options) => async ( dispatch: Dispatch<AppActions>, getState: () => AppState ) => {
  if (options.sessionInfo?.id === "") {
    dispatch(setNotification({
      severity: "error",
      title: "No Session Found",
      message: "",
      autohide: null
    }))
  }

  dispatch({
    type: c.SET_OPTIONS,
    payload: options
  })
}

export const setCommentState = (state: Comment['state']): OptionsAction => {
  return {
    type: c.SET_COMMENT_STATE,
    payload: state
  }
}
export const setComment = (comment: Comment) => {
  return {
    type: c.SET_COMMENT,
    payload: comment
  }
}