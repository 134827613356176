// Values
import { INIT_NOTIFICATION } from './reducer'

// Types
import { c, Notification } from "./types"


export const setNotification = (notification: Notification) => {
  if (!notification.autohide && notification.autohide !== null) {
    notification.autohide = 5000
  }

  return {
    type: c.SET_NOTIFICATION,
    payload: notification
  }
}
  
export const clearNotification = () => {
  return {
    type: c.SET_NOTIFICATION,
    payload: INIT_NOTIFICATION
  }
}