import { Moment } from 'moment'

export const c = {
  SET_OPTIONS: "SET_OPTIONS",
  SET_COMMENT_STATE: "SET_COMMENT_STATE",
  SET_COMMENT: "SET_COMMENT"
}
export interface Comment {
  state: "hide" | "show" | "reject" | "use"
  text: string
}
export interface Options {
  sessionInfo?: {
    id: string
    photographerName: string
    firstName: string
    lastName: string
    templateName: string
    cityName: string
    sessionDate: Moment
  }
  mode: string
  minImg: number
  maxImg: number
  failOnCount: boolean
  minSize: number
  minAvgSize: number
  failOnSize: boolean

  comment: Comment
}

export interface CommentStateAction {
  type: string
  payload: Comment['state']
}
export interface CommentAction {
  type: string
  payload: Comment
}
export interface SetOptionsAction {
  type: string
  payload: Options
}
export type OptionsAction =
  | CommentStateAction
  | CommentAction
  | SetOptionsAction