import { c, Images, ImagesActions } from './types'


export const INIT_IMAGES: Images = {
  files: [],
  allDone: false,

  areValidating: false,
  areValid: false,
  validationStage: 0,

  areUploading: false,

  confirmation: [],
}


export const images = (state: Images = INIT_IMAGES, action: ImagesActions): Images => {
  switch (action.type) {
    case c.ADD_FILES:
      return {
        ...state,
        files: [
          ...state.files,
          ...action.payload as Images['files'],
        ]
      }
    case c.REMOVE_FILE:
      const p1 = action.payload as number
      return {
        ...state,
        files: [
          ...state.files.slice(0, p1),
          ...state.files.slice(p1+1)
        ]
      }
    case c.CLEAR_FILES:
      return {
        ...state,
        files: []
      }
    case c.SET_FILE_PROGRESS:
      const p2 = action.payload as {index: number, progress: number, transfered: boolean} 
      return {
        ...state,
        files: [
          ...state.files.slice(0, p2.index),
          {
            name: state.files[p2.index].name,
            image: state.files[p2.index].image,
            progress: p2.progress,
            transfered: p2.transfered
          },
          ...state.files.slice(p2.index+1)
        ]
      }
    case c.SET_ALL_DONE:
      return {
        ...state,
        allDone: action.payload as boolean
      }

    case c.SET_FILES_VALIDATING:
      return {
        ...state,
        areValidating: action.payload as boolean
      }
    case c.SET_FILES_VALID:
      return {
        ...state,
        areValid: action.payload as boolean
      }
    case c.SET_VALIDATION_STAGE:
      return {
        ...state,
        validationStage: state.validationStage += action.payload as number
      }
    case c.CLEAR_VALIDATION_STAGE:
      return {
        ...state,
        validationStage: 0
      }


    case c.SET_FILES_UPLOADING:
      return {
        ...state,
        areUploading: action.payload as boolean
      }

      
    case c.ADD_CONFIRMATION_MESSAGE:
      return {
        ...state,
        confirmation: [
            ...state.confirmation,
            action.payload as string
          ],
      }
    case c.CLEAR_CONFIRMATION_MESSAGE:
      return {
        ...state,
        confirmation: []
      }
    default:
      return state
  }
}