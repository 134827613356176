import AWS from 'aws-sdk'
import { queue } from 'async'

import { store } from '..'
import { setFileProgress } from './actions'

interface Task {
  file: File
  folderName: string
  fileName: string
}
interface DoneFileContent {
  mode: string
  comment: string
  confirmationMessages: string[]
}

const RETRY_ATTEMPTS = 5
const CONCURENT_REQUESTS = 3
const BUCKET = 'uploader-galleries'

AWS.config = new AWS.Config()
AWS.config.update({
  region: 'us-east-1',
  credentials: new AWS.Credentials({
    accessKeyId: 'AKIA35Z4XUOXSNMLWM4O',
    secretAccessKey: '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'
  })
})


export const uploadAllFiles = (files: File[], folders: string[]): Promise<void> => {
  return new Promise((resolve, reject) => {
    const q = queue( (task: Task, callback) => {
      uploadFile(task.file, task.folderName, task.fileName).then(()=> {
        callback(null)
      }).catch((err) => {
        callback(err)
      })
    }, CONCURENT_REQUESTS )

    let retriesLeft = RETRY_ATTEMPTS

    files.forEach( (file, index) => {
      
      let fileNumbers: string
      try {
        fileNumbers = [...file.name.matchAll(/\d+/g)].map( r => r[0]).join(':')
      } catch (err) {
        throw new Error('Browser not supported, please use Chrome v73+ (recommended) or Safari v13+')
      }

      const task: Task = {
        file: file,
        folderName: folders.join('/'),
        fileName: `${folders[folders.length-1]}_${fileNumbers}_${index}.jpeg`
      }
      const callback = (err: Error | null | undefined) => {
        if (err) {
          if (retriesLeft < 1) {
            q.kill()
            reject(err)
          }
          else {
            console.log(`ERROR: multipart upload task, retries left: ${retriesLeft}`)
            retriesLeft -= 1
            q.push(task, callback)
          }
        }
      }
      q.push(task, callback)
    })
  
    q.drain().then(() => {
      resolve()
    })
  })
}

const uploadFile = (file: File, folderName: string, fileName: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const upload = new AWS.S3.ManagedUpload({
      leavePartsOnError: false,
      queueSize: 1,
      params: {
        Bucket: BUCKET,
        Key: `${folderName}/${fileName}`, 
        Body: file,
      }
    }).on('httpUploadProgress', (progress) => {
      if (progress.total) {
        // Just trying to find a way to reduce the overall amount of update call
        const rdn = Math.floor(Math.random() * Math.floor(10))
        if (rdn === 0) {
          const percentage = (progress.loaded * 100) / progress.total
          updateFileProgress(file.name, percentage)
        }

      }
    })
    
    upload.promise().then(
      (data: any) => {
        updateFileProgress(file.name, 100, true)
        resolve(data)
      },
      (err: any) => {
        console.log("Upload.promise error: ", file.name , err)
        reject(err)
      }
    )

  })
}

export const uploadDoneFile = (body: DoneFileContent, folders: string[]): Promise<null> => {
  return new Promise((resolve, reject) => { 
    const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: BUCKET,
        Key: `${folders.join('/')}/${folders[folders.length-1]}.done`,
        Body: JSON.stringify(body)
      }
    }).on('httpUploadProgress', (progress) => {
      if (progress.total) {
        const stage = (progress.loaded * 100) / progress.total
        console.log("Uploading done file: ", stage)
      }
    })
    
    upload.promise().then(
      (data: any) => {
        console.log("Final Done: ", data)
        resolve(data)
      },
      (err: any) => {
        console.log("Final Error: ", err)
        reject(err.message)
      }
    )
  })
}

const updateFileProgress = (fileName: string, progress: number, transfered?: boolean) => (
  store.dispatch(setFileProgress(fileName, progress, transfered))
)