import { connect, ConnectedProps } from "react-redux"

import { AppUi } from "./App"

// Actions
import { setOptions } from '../../store/options/actions'

// Types
import { ThunkDispatch } from "redux-thunk"
import { AppState, AppActions } from "../../store/types"
import { Options } from "../../store/options/types"

const mapState = (state: AppState) => {
  return {
    numberOfFiles: state.images.files.length,
    steps: {
      // Step 0 - Import
      import: !state.images.areValidating && !state.images.areValid,
      // Step 1 - Validate and potentially confirm
      validation: state.images.areValidating,
      confirmation: state.images.confirmation.length !== 0
        && !state.images.areValidating
        && !state.images.areUploading
        && state.options.comment.state !== 'show',
      // Step 2 - Add Comment
      comment: state.options.comment.state === "show",
      // Step 3 - Uploading
      upload: state.images.areUploading,
      // Step 4 - done
      done: state.images.allDone
    }
  }
}

const mapDispatch = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return {
    setOptions(options: Options) {
      dispatch(setOptions(options))
    }
  }
}

const connector = connect(mapState, mapDispatch)
export type PropsFromRedux = ConnectedProps<typeof connector>


export default connector(AppUi)