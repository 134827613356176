import { queue } from 'async'
import FileType from 'file-type/browser'

// Importing store directly since passing down would be a hassle
import { store } from '..'
import { setValidationStage, clearValidationStage, addConfirmationMessage } from './actions'

//types
import { Options } from '../options/types'

interface Task {
  file: File
}

const CONCURENT_VALIDATION = 10

export const validateImages = (images: File[], opt: Options): Promise<void> => {
  resetValidationStep()
  return new Promise((resolve, reject) => {
    const total = images.length

    // Check images total count
    if (total < opt.minImg) {
      if (opt.failOnCount) {
        reject(new Error(`Can not have less then ${opt.minImg} images`))
      }
      else {
        addWarning(`You have less then ${opt.minImg} images`)
      }
    }
    if (total > opt.maxImg) {
      if (opt.failOnCount) {
        reject(new Error(`Can not have more then ${opt.maxImg} files.`))
      }
      else {
        addWarning(`You have more then ${opt.maxImg} images`)
      }
    }

    // Check images sync operation
    let totalSize = 0
    images.forEach( f => {
      const path = f.name
      // Check image naming
      const sections = path.split('.')
      if (!['jpg', 'jpeg', 'jpe'].includes(sections[sections.length - 1].toLowerCase())) {
        reject(new Error(`${path} does not end with one of the proper extension type: '.jpg', '.jpeg', '.jpe'`))
      }
      // Check image size
      const imgSize = f.size / 1000000
      totalSize += imgSize
      if (imgSize > 45) {
        reject(new Error(`${path} is ${imgSize.toFixed(2)}MB but cannot be greater then 45MB`))
      }
      if (imgSize < opt.minSize) {
        if (opt.failOnSize) {
          reject(new Error(`${path} is ${imgSize.toFixed(2)}MB but must be greater then ${opt.minSize}MB`))
        }
        else {
          addWarning(`Your image ${path} is smaller than the required ${opt.minSize}MB`)
        }
      }
    })

    if ((totalSize / images.length) < opt.minAvgSize) {
      if (opt.failOnSize) {
        reject(new Error(`Your average image size must be greater then ${opt.minAvgSize}MB`))
      }
      else {
        addWarning(`Your average image size is smaller then the required ${opt.minAvgSize}MB`)
      }
    }

    // Check image async operation
    const q = queue( (task: Task, callback) => {
      getFileType(task.file).then(()=> {
        callback(null)
      }).catch((err) => {
        callback(err)
      })
    }, CONCURENT_VALIDATION )
    q.drain().then(() => {
      // Once all files have been read and validated, resolve global promise
      resolve()
    })
    for (let i = 0; i < images.length; i ++) {
      q.push({file: images[i]}, (err) => {
        if (err) {
          q.kill()
          reject(err)
        }
      })
    }
  })
}

const getFileType = (file: File): Promise<null> => {
  return new Promise((resolve, reject) => {
    FileType.fromBlob(file).then( meta => {
      if (meta?.ext !== "jpg") {
        reject(new Error(`${file.name} extension is '${meta?.ext}' and not 'jpg'`))
      }
      if (meta?.mime !== "image/jpeg") {
        reject(new Error(`${file.name} type is '${meta?.ext}' and not 'image/jpeg'`))
      }
      updateValidationStep(1)
      resolve()
    })
  })
}
const addWarning = (message: string) => {
  store.dispatch(addConfirmationMessage(message))
}
const updateValidationStep = (step: number) => {
  store.dispatch(setValidationStage(step))
}
const resetValidationStep = () => {
  store.dispatch(clearValidationStage())
}