import { connect, ConnectedProps } from "react-redux"

import { ConfirmationUi } from "./Confirmation"

// Actions
import { clearConfirmationMessages, setIsValid } from "../../store/images/actions"
import { setCommentState } from '../../store/options/actions'
// Types
import { ThunkDispatch } from "redux-thunk"
import { AppState, AppActions } from "../../store/types"


const mapState = (state: AppState) => {
  return {
    isDefault: state.options.mode === "default",
    firstFile: state.images.files[0].image,
    conditions: state.images.confirmation,
    numberOfFiles: state.images.files.length
  }
}

const mapDispatch = (dispatch: ThunkDispatch<any, any, AppActions>) => {
  return {
    accept() {
      dispatch(setCommentState('show'))
    },
    decline() {
      dispatch(clearConfirmationMessages())
      dispatch(setIsValid(false))
    }
  }
}

const connector = connect(mapState, mapDispatch)
export type PropsFromRedux = ConnectedProps<typeof connector>


export default connector(ConfirmationUi)