import React from 'react'
import moment from 'moment'
import { useDropzone, DropzoneOptions } from 'react-dropzone'

// Types
import { PropsFromRedux } from "./index"

// MUI
import { Box, Typography, Button, IconButton, Grid } from '@material-ui/core'
import { PhotoLibraryOutlined, Image, RemoveCircle } from '@material-ui/icons'
import { makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropzone: {
      height: "450px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.background.default,
      border: `1px dashed  ${theme.palette.secondary.main}`,
      borderRadius: "10px",
    },
    fileList: {
      height: "450px",
      overflowY: "auto",
      overflowX: "hidden",
    },
    removeIcon: {
      color: theme.palette.error.main
    }
  })
)

export const DropzoneUi = (props: PropsFromRedux) => {
  const cls = useStyles()
  const handleDrop = (files: File[]) => {
    props.addFiles(files)
  }
  const dropzoneOptions: DropzoneOptions = {
    onDrop: handleDrop,
    disabled: props.disabled
  }
  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions)

  return (<>
    <Grid container spacing={2}>
      <Grid item xs={props.hasFiles ? 6 : 12}> 
        <Box {...getRootProps({ className: cls.dropzone })}>
          <input {...getInputProps()} accept=".jpg, .jpeg, .jpe"/>
          <PhotoLibraryOutlined fontSize="large" />
          <Box my={1} />
          <Typography variant="caption">{props.mode}: Drag 'n' drop all images here, or click to select files</Typography>
        </Box>
      </Grid>
      {props.hasFiles ? (
      <Grid item xs={6} className={cls.fileList}> 
        {props.files.map( (f, i) => (
          <Box key={i} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <Image fontSize="large"/>
              <Box mx={1} />
              <Box display="flex" flexDirection="column">
                <Typography variant="body1">{f.name} - {(f.image.size/1000000).toFixed(1)}MB</Typography>
                <Typography variant="caption">Last Modified - {moment(f.image.lastModified).format("YYYY/MM/DD h:mmA")}</Typography>
              </Box>
            </Box>
            <IconButton size="small"  onClick={()=>props.removeFile(i)}>
              <RemoveCircle className={cls.removeIcon}/>
            </IconButton>
          </Box>
        ))}
      </Grid>
      ) : null }
    </Grid>
    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
      <Button
        variant="outlined"
        color="secondary"
        onClick={props.clearFiles}
        disabled={props.files.length === 0}
        style={{width:"160px"}}
      >
        Clear
      </Button>
      <Box mx={2} />
      <Button
        variant="contained"
        color="primary"
        onClick={()=>props.validateFiles()}
        disabled={props.files.length === 0}
        style={{width:"160px"}}
      >
        confirm {props.files.length} Files
      </Button>

    </Box></>
  )
}