import React from 'react'

import { Stepper, Step, StepLabel} from '@material-ui/core'

export default (props: {activeStep: number}) => {

  return (
    <Stepper activeStep={props.activeStep} style={{padding: 0}}>
      <Step completed={props.activeStep > 0}>
        <StepLabel>Import</StepLabel>
      </Step>
      <Step completed={props.activeStep > 1}>
        <StepLabel>Validate</StepLabel>
      </Step>
      <Step completed={props.activeStep > 2}>
        <StepLabel>Comments</StepLabel>
      </Step>
      <Step completed={props.activeStep > 3}>
        <StepLabel>Upload</StepLabel>
      </Step>
    </Stepper>
  )
}